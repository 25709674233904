import React, { useEffect } from 'react';

import Drawer from '@mui/material/Drawer';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { LocalVideoTrack } from 'twilio-video/tsdef/LocalVideoTrack';

import useVideoContext from '../../hooks/useVideoContext';
import VideoTrack from '../VideoTrack';
import AddVideoSessionButton from './AddVideoSessionButton';
import MyDevicesSelectionHeader from './MyDevicesSelectionHeader';

const useStyles = makeStyles((theme: Theme) => ({
	drawer: {
		display: 'flex',
		width: 320,
		height: '100%',
	},
	thumbnailContainer: {
		display: 'grid',
		flexWrap: 'wrap',
		padding: '5px',
		placeItems: 'center',
		justifyContent: 'center',
		overflow: 'auto',
	},
	innerContainer: {
		width: 'auto',
		height: '215px',
		marginTop: '5px',
	},
	addVideoStreamButton: {
		margin: '0 auto',
		display: 'block',
	},
}));

function MyDevicesSelectionDialog() {
	function createRandomString(length: number) {
		let result = '';
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const charactersLength = characters.length;

		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}

		return result;
	}
	const base_url = process.env.REACT_APP_BASE_URL;

	const classes = useStyles();
	const { isMyDevicesSelectionOpen, setIsMyDevicesSelectionOpen } = useVideoContext();
	const { localTracks, room, extraStreamUrls } = useVideoContext();
	const [refresh, setRefresh] = React.useState(false);
	const videoTrack = localTracks.find(
		track => !track.name.includes('screen') && track.kind === 'video'
	) as LocalVideoTrack;

	useEffect(() => {}, [refresh]);

	// function openNewSession() {
	//   window.open(
	//     `${base_url}/room/${room_name}?user_identity=${user_identity}&no_audio=true`,
	//     '_blank'
	//   );
	// }

	function addNewStream() {
		const room_name = room?.name || 'unknown';
		const user_identity =
			(room?.localParticipant?.identity || 'unknown') + '-' + createRandomString(4);

		const newStreamUrl = `${base_url}/room_device/${room_name}?user_identity=${user_identity}&no_audio=true`;
		extraStreamUrls.push(newStreamUrl);

		setRefresh(!refresh);
	}

	return (
		<Drawer
			variant="persistent"
			anchor="left"
			open={isMyDevicesSelectionOpen}
			transitionDuration={0}
			classes={{
				paper: classes.drawer,
			}}
		>
			<MyDevicesSelectionHeader onClose={() => setIsMyDevicesSelectionOpen(false)} />
			<div className={classes.thumbnailContainer}>
				<div className={classes.innerContainer}>
					<VideoTrack track={videoTrack} isLocal />
				</div>

				{extraStreamUrls.map(extraStreamUrl => {
					return (
						<iframe
							title={extraStreamUrl}
							src={extraStreamUrl}
							className={classes.innerContainer}
							scrolling="no"
							style={{ backgroundColor: 'green' }}
						></iframe>
					);
				})}

				<div className={classes.innerContainer}>
					<AddVideoSessionButton className={classes.addVideoStreamButton} onClick={addNewStream} />
				</div>
			</div>
		</Drawer>
	);
}

export default MyDevicesSelectionDialog;
